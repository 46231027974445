import { OptionList } from "../configurator/types";

export interface EditOrderState {
    orderId?: number;
    order?: Order;
    isOrderFetching: boolean;
    didOrderInvalidate: boolean;
    totalPriceDiscount?: number;

    editPosting?: boolean;
    editSucceeded?: boolean;
    editFailed?: boolean;
}

export const LOAD_ORDER_RESET = 'LOAD_ORDER_RESET';

export const LOAD_ORDER_STARTED = 'LOAD_ORDER_STARTED';
export const LOAD_ORDER_SUCCESS = 'LOAD_ORDER_SUCCESS';
export const LOAD_ORDER_FAILURE = 'LOAD_ORDER_FAILURE';

export const EDIT_ORDER_STARTED = 'EDIT_ORDER_STARTED';
export const EDIT_ORDER_SUCCESS = 'EDIT_ORDER_SUCCESS';
export const EDIT_ORDER_FAILURE = 'EDIT_ORDER_FAILURE';

export interface LoadOrderStarted {
    type: typeof LOAD_ORDER_STARTED;
}

export interface LoadOrderSucces {
    type: typeof LOAD_ORDER_SUCCESS;
    payload: {
        orderId: number;
        order: Order;
    };
}

export interface LoadOrderFailure {
    type: typeof LOAD_ORDER_FAILURE;
}

export interface LoadOrderReset {
    type: typeof LOAD_ORDER_RESET;
}

export interface EditOrderStarted {
    type: typeof EDIT_ORDER_STARTED;
}

export interface EditOrderSucces {
    type: typeof EDIT_ORDER_SUCCESS;
}

export interface EditOrderFailure {
    type: typeof EDIT_ORDER_FAILURE;
}

export type OrderProductAlterationDataData = {
    optionId: number;
    optionListId: number;
    dependency?: OptionList;
    dependencyOptionListId?: number;
    dependencyOptionListOptionLineNr?: number;
}

export interface OrderProductAlterationData {
    type: string;
    data: string | OrderProductAlterationDataData;
}

export interface OrderProductAlteration {
    content: string;
    price: number;
    data: OrderProductAlterationData[];
}

export interface OrderInvoiceUser {
    id: number;
    firstName: string;
    insertion?: string;
    lastName: string;
    fullName: string;
    phone: string;
    email: string;
}

export interface OrderInvoice {
    user: OrderInvoiceUser;
    company?: string;
    city: string;
    houseNumber: number;
    houseNumberAddition: string;
    street: string;
    zipCode: string;
    country: string;
    countryISO3166Code: string;
    countryId: number;
}
export interface OrderProduct {
    productId: number;
    alterations: OrderProductAlteration[];
}

export interface Order {
    id: number;
    productTotalPrice: number;
    products: OrderProduct[];
    invoice: OrderInvoice;
    description: string;
}

export type EditOrderActionTypes =
    | LoadOrderReset
    | LoadOrderStarted
    | LoadOrderSucces
    | LoadOrderFailure
    | EditOrderStarted
    | EditOrderSucces
    | EditOrderFailure;