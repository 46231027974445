import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { Option } from '../../../store/configurator/types';
import { getImageValueSrc } from '../../../utils/cdn';
import numeral from 'numeral';
import 'numeral/locales/nl-nl';

numeral.locale('nl-nl');

interface ImageOptionProps {
  onClick: () => void;
  option: Option;
}


const LargeImage = styled.div`
  position: absolute;
  z-index: 2;
  right: 100%;
  top: 50%;
  transform: translateX(-1rem) translateY(-50%);
  width: clamp(15rem, 15vw, 30rem);
  height: clamp(15rem, 15vw, 30rem);
  background-color: ${({ theme }) => theme.colors.gray[700]};
  box-shadow: 0px 0px 1rem 0px rgba(0,0,0,.15);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

const Wrapper = styled.div<{
  selected?: boolean;
}>`
  height: 5rem;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  position: relative;

  ${({ selected }) =>
    !selected &&
    css`
      border-top: 1px solid #eceff1;

      &:first-child {
        border-top: 1px solid transparent;
      }

      &:hover {
        cursor: pointer;
      }
    `}

  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.colors.primary};
      border-top: 1px solid #eceff1;
      background-color: #eceff1;

      & + & {
        border-top-color: transparent !important;
      }
    `}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.9375rem;
  flex: 1;
  min-width: 0;
`;

const Title = styled.span`
  color: #455a64;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Price = styled.span`
  color: #90a4ae;
  margin-top: 0.125rem;
`;

const Thumbnail = styled.img`
  width: 4rem;
  height: 3rem;
  margin-right: 1rem;
`;

const ImageOption: FC<ImageOptionProps> = ({ option, onClick }) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const thumbnail = option.imageValues.find(
    (imageValue) => imageValue.name === 'thumbnail',
  );

  const previewImageValue = option.imageValues.find(
    (imageValue) => imageValue.name === 'photo_preview'
  );

  return (
    <Wrapper
      selected={option.selected}
      onClick={onClick}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}>
      {thumbnail && (
        <Thumbnail
          src={getImageValueSrc(
            thumbnail.fileName,
            thumbnail.fileHash,
            128,
            96,
          )}
        />
      )}
      <TextWrapper>
        <Title>{option.name}</Title>
        {option.price > 0 && (
          <Price>{numeral(option.price).format('$0,0.00')}</Price>
        )}
      </TextWrapper>
      {
        isOpen && !!previewImageValue &&
        <LargeImage style={{ backgroundImage: `url(${getImageValueSrc(previewImageValue.fileName, previewImageValue.fileHash, 700, 700)})` }} />
      }
    </Wrapper>
  );
};

export default ImageOption;
