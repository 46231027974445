import {
    EditOrderActionTypes,
    LOAD_ORDER_STARTED,
    LOAD_ORDER_SUCCESS,
    LOAD_ORDER_FAILURE,
    LOAD_ORDER_RESET,
    EDIT_ORDER_FAILURE,
    EDIT_ORDER_STARTED,
    EDIT_ORDER_SUCCESS,
    Order,
} from './types';
import { Dispatch } from 'redux';
import api from '../../api';
import { CartOrder } from '../order/types';

export const loadOrder = (
    orderId: number
) => {
    return async (dispatch: Dispatch): Promise<boolean> => {
        dispatch(loadStarted());

        try {
            const order = await api.get<Order>(
                `${process.env.REACT_APP_API_ROOT}webshop/order/details/${process.env.REACT_APP_SITE_ID}/${orderId}`,
            );

            dispatch(loadOrderSuccess(orderId, order.data));
            return true;
        } catch (e) {
            dispatch(loadOrderFailure());
            return false;
        }
    };
};

export const editOrder = (orderId: number, orderModel: CartOrder) => {
    return async (dispatch: Dispatch) => {
        dispatch(editOrderStarted());

        try {
            await api.post<{ data: { orderId: number } }>(
                `${process.env.REACT_APP_API_ROOT}webshop/order/Edit/${process.env.REACT_APP_SITE_ID}/${orderId}`,
                orderModel,
            )

            dispatch(editOrderSuccess());
        } catch (e) {
            dispatch(editOrderFailure());
        }
    };
};

export const loadOrderReset = (): EditOrderActionTypes => ({
    type: LOAD_ORDER_RESET,
});

export const loadStarted = () => ({
    type: LOAD_ORDER_STARTED
});

export const loadOrderSuccess = (orderId: number, order: Order) => ({
    type: LOAD_ORDER_SUCCESS,
    payload: {
        orderId,
        order
    },
});

export const loadOrderFailure = () => ({
    type: LOAD_ORDER_FAILURE
});


export const editOrderFailure = (): EditOrderActionTypes => ({
    type: EDIT_ORDER_FAILURE,
});

export const editOrderStarted = () => ({
    type: EDIT_ORDER_STARTED
});

export const editOrderSuccess = () => ({
    type: EDIT_ORDER_SUCCESS
});