import {
    EditOrderActionTypes,
    EditOrderState,
    LOAD_ORDER_RESET,
    LOAD_ORDER_STARTED,
    LOAD_ORDER_SUCCESS,
    LOAD_ORDER_FAILURE,
    EDIT_ORDER_STARTED,
    EDIT_ORDER_SUCCESS,
    EDIT_ORDER_FAILURE,
    LoadOrderSucces
} from './types';

const initialState: EditOrderState = {
    orderId: undefined,
    order: undefined,
    isOrderFetching: false,
    didOrderInvalidate: false,
    totalPriceDiscount: undefined
};

const loadOrderStarted = (state: EditOrderState): EditOrderState => ({
    ...state,
    isOrderFetching: true
});

const loadOrderSuccess = (state: EditOrderState, action: LoadOrderSucces): EditOrderState => ({
    ...state,
    orderId: action.payload.orderId,
    order: action.payload.order,
    didOrderInvalidate: false,
    isOrderFetching: false
});

const loadOrderFailure = (state: EditOrderState): EditOrderState => ({
    ...state,
    editPosting: false,
    editSucceeded: false,
    editFailed: true,
});

const editOrderStarted = (state: EditOrderState): EditOrderState => ({
    ...state,
    editPosting: true,
    editSucceeded: false,
    editFailed: false,
});

const editOrderSuccess = (state: EditOrderState): EditOrderState => ({
    ...state,
    editPosting: false,
    editSucceeded: true,
    editFailed: false,
});

const editOrderFailure = (state: EditOrderState): EditOrderState => ({
    ...state,
    didOrderInvalidate: true,
    isOrderFetching: false
});

const loadOrderReset = (state: EditOrderState): EditOrderState => {
    return {
        ...state,
        ...initialState
    };
};

export const editOrderReducer = (
    state = initialState,
    action: EditOrderActionTypes,
): EditOrderState => {
    switch (action.type) {
        case LOAD_ORDER_STARTED:
            return loadOrderStarted(state);
        case LOAD_ORDER_SUCCESS:
            return loadOrderSuccess(state, action);
        case LOAD_ORDER_FAILURE:
            return loadOrderFailure(state);
        case LOAD_ORDER_RESET:
            return loadOrderReset(state);
        case EDIT_ORDER_STARTED:
            return editOrderStarted(state);
        case EDIT_ORDER_SUCCESS:
            return editOrderSuccess(state);
        case EDIT_ORDER_FAILURE:
            return editOrderFailure(state);
        default:
            return state;
    }
};
