import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { Option } from '../../../store/configurator/types';
import numeral from 'numeral';
import { getImageValueSrc } from '../../../utils/cdn';

interface TextOptionProps {
  onClick: () => void;
  option: Option;
}

const LargeImage = styled.div`
  position: absolute;
  z-index: 2;
  right: 100%;
  top: 50%;
  transform: translateX(-1rem) translateY(-50%);
  width: clamp(15rem, 15vw, 30rem);
  height: clamp(15rem, 15vw, 30rem);
  background-color: ${({ theme }) => theme.colors.gray[700]};
  box-shadow: 0px 0px 1rem 0px rgba(0,0,0,.15);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

const Wrapper = styled.div<{
  selected: boolean;
}>`
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  margin: 0 6px 16px;
  height: 32px;
  border: 1px solid #eceff1;
  padding: 0 8px;
  color: #90a4ae;
  position: relative;

  ${({ selected }) =>
    selected &&
    css`
      font-weight: 700;
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.primary};
      border: none;
    `}

  &:hover {
    cursor: pointer;
  }
`;

const Price = styled.span`
  font-size: 0.75rem;
  margin-left: 0.25rem;
`;

const TextOption: FC<TextOptionProps> = ({ option, onClick }) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const previewImageValue = option.imageValues.find(
    (imageValue) => imageValue.name === 'photo_preview'
  );

  return (
    <Wrapper
      selected={!!option.selected}
      onClick={onClick}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}>
      {option.name}
      {option.price !== 0 && <Price>{`(+ ${numeral(option.price).format('$0,0.00')})`}</Price>}
      {
        isOpen && !!previewImageValue &&
        <LargeImage style={{ backgroundImage: `url(${getImageValueSrc(previewImageValue.fileName, previewImageValue.fileHash, 700, 700)})` }} />
      }
    </Wrapper>
  );
};

export default TextOption;
